<template>
  <div>
    <v-container>
      <h2 class="mb-4">Hazards</h2>

      <p class="mb-3">
        For each of the <b>5 chemicals</b> in the table below, look up the Safety Data Sheet (SDS)
        by using the "Products" search field at the top of the Millipore Sigma webpage by clicking
        on the textbook icon. Search for the chemical indicated & click on the SDS link to the left
        of the chemical. If a concentration is given, find a listing of the chemical with an equal
        or slightly higher concentration if possible. Choose the next highest concentration if you
        cannot find the one listed. For example, search sodium hydroxide solution, if 0.25 M is not
        available choose the solution with 0.25 N (N is equivalent to M) or the closest higher
        concentration - 0.5 M.
      </p>

      <p class="mb-5">
        For the GHS symbols that are indicated for the chemical, look just below the GHS Pictograms
        to find the Signal Word and Hazard Statements. Match each Hazard Statement to the GHS symbol
        present below. If the GHS symbol is not listed do not select any options. If no signal word
        is present, please select the "None" option from the dropdown menu.
      </p>

      <div class="table-container">
        <table class="hazards-table">
          <thead>
            <tr class="sticky-row">
              <th
                class="sticky-column"
                style="font-weight: bold; text-align: center; min-width: 107px"
              >
                GHS Symbols
              </th>
              <th
                v-for="molecule in molecules"
                :key="molecule.label"
                style="font-weight: bold; text-align: left; min-width: 250px"
              >
                {{ molecule.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="hazard in hazards" :key="hazard.hazardTitle">
              <td class="sticky-column">
                <span class="center-column">
                  {{ hazard.hazardTitle }}
                  <v-img :src="hazard.hazardImage" style="width: 74px" />
                </span>
              </td>
              <td
                v-for="label in hazard.labels"
                :key="label.labelMol"
                class="py-2 px-1 mx-0 centered-input"
              >
                <v-combobox
                  v-model="inputs[label.labelMol]"
                  :items="items"
                  multiple
                  small-chips
                  deletable-chips
                  outlined
                >
                </v-combobox>
              </td>
            </tr>

            <tr>
              <td class="sticky-column centered-input">j) Signal Word</td>
              <td
                v-for="swInput in swInputs"
                :key="swInput.name"
                class="py-2 px-1 mx-0 centered-input"
              >
                <v-select
                  v-model="inputs[swInput.name]"
                  :items="signalWords"
                  class="my-4"
                  outlined
                  small-chips
                  deletable-chips
                  hide-details
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-container>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'Chem1LC_Q2S1_HazardsTable',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        A1: [],
        A2: [],
        A3: [],
        A4: [],
        A5: [],
        A6: [],
        A7: [],
        A8: [],
        A9: [],

        B1: [],
        B2: [],
        B3: [],
        B4: [],
        B5: [],
        B6: [],
        B7: [],
        B8: [],
        B9: [],

        C1: [],
        C2: [],
        C3: [],
        C4: [],
        C5: [],
        C6: [],
        C7: [],
        C8: [],
        C9: [],

        D1: [],
        D2: [],
        D3: [],
        D4: [],
        D5: [],
        D6: [],
        D7: [],
        D8: [],
        D9: [],

        E1: [],
        E2: [],
        E3: [],
        E4: [],
        E5: [],
        E6: [],
        E7: [],
        E8: [],
        E9: [],

        SWA: null,
        SWB: null,
        SWC: null,
        SWD: null,
        SWE: null,
      },
      items: [
        'Causes eye damage',
        'Causes eye irritation',
        'Causes skin burns',
        'Causes skin irritation',
        'Combustible liquid',
        'Flammable liquid and vapor',
        'Harmful if swallowed',
        'Harmful to aquatic life',
        'May be corrosive to metals',
        'May be fatal if swallowed and enters airway',
        'May cause allergy, asthma, or breathing issues if inhaled',
        'May cause an allergic skin reaction',
        'May cause drowsiness or dizziness',
        'May cause respiratory irritation',
        'Suspected of causing cancer',
      ],
      signalWords: ['None', 'Warning', 'Danger'],
      molecules: [
        {name: 'Water (deionized)', label: 'water'},
        {name: 'Allura Red AC (Red 40)', label: 'alluraRed'},
        {name: 'Brilliant Blue FCF (Blue 1)', label: 'brilliantBlue'},
        {name: 'Tartrazine (Yellow 5)', label: 'tartrazine'},
        {name: 'Sunset Yellow FCF (Yellow 6)', label: 'sunsetYellow'},
      ],
      swInputs: [{name: 'SWA'}, {name: 'SWB'}, {name: 'SWC'}, {name: 'SWD'}, {name: 'SWE'}],
      hazards: [
        {
          hazardTitle: 'a) health hazard',
          hazardImage: '/img/assignments/ghsSymbol1.jpg',
          labels: [
            {labelMol: 'A1'},
            {labelMol: 'B1'},
            {labelMol: 'C1'},
            {labelMol: 'D1'},
            {labelMol: 'E1'},
          ],
        },
        {
          hazardTitle: 'b) flame',
          hazardImage: '/img/assignments/ghsSymbol2.jpg',
          labels: [
            {labelMol: 'A2'},
            {labelMol: 'B2'},
            {labelMol: 'C2'},
            {labelMol: 'D2'},
            {labelMol: 'E2'},
          ],
        },
        {
          hazardTitle: 'c) flame over circle',
          hazardImage: '/img/assignments/ghsSymbol7.jpg',
          labels: [
            {labelMol: 'A3'},
            {labelMol: 'B3'},
            {labelMol: 'C3'},
            {labelMol: 'D3'},
            {labelMol: 'E3'},
          ],
        },
        {
          hazardTitle: 'd) exclamation mark',
          hazardImage: '/img/assignments/ghsSymbol3.jpg',
          labels: [
            {labelMol: 'A4'},
            {labelMol: 'B4'},
            {labelMol: 'C4'},
            {labelMol: 'D4'},
            {labelMol: 'E4'},
          ],
        },
        {
          hazardTitle: 'e) corrosion',
          hazardImage: '/img/assignments/ghsSymbol5.jpg',
          labels: [
            {labelMol: 'A5'},
            {labelMol: 'B5'},
            {labelMol: 'C5'},
            {labelMol: 'D5'},
            {labelMol: 'E5'},
          ],
        },
        {
          hazardTitle: 'f) skull & crossbones',
          hazardImage: '/img/assignments/ghsSymbol8.jpg',
          labels: [
            {labelMol: 'A6'},
            {labelMol: 'B6'},
            {labelMol: 'C6'},
            {labelMol: 'D6'},
            {labelMol: 'E6'},
          ],
        },
        {
          hazardTitle: 'g) gas cylinder',
          hazardImage: '/img/assignments/ghsSymbol4.jpg',
          labels: [
            {labelMol: 'A7'},
            {labelMol: 'B7'},
            {labelMol: 'C7'},
            {labelMol: 'D7'},
            {labelMol: 'E7'},
          ],
        },
        {
          hazardTitle: 'h) exploding bomb',
          hazardImage: '/img/assignments/ghsSymbol6.jpg',
          labels: [
            {labelMol: 'A8'},
            {labelMol: 'B8'},
            {labelMol: 'C8'},
            {labelMol: 'D8'},
            {labelMol: 'E8'},
          ],
        },
        {
          hazardTitle: 'i) environment',
          hazardImage: '/img/assignments/ghsSymbol9.jpg',
          labels: [
            {labelMol: 'A9'},
            {labelMol: 'B9'},
            {labelMol: 'C9'},
            {labelMol: 'D9'},
            {labelMol: 'E9'},
          ],
        },
      ],
    };
  },
  computed: {},
};
</script>

<style scoped>
.table-container {
  position: relative;
  overflow-x: auto;
  background: /* Shadow Cover RIGHT */
    linear-gradient(to left, white 1rem, rgba(255, 255, 255, 0)) center right,
    /* Shadow RIGHT */ linear-gradient(to left, rgba(27, 19, 53, 0.07), rgba(27, 19, 53, 0)) center
      right,
    /* Shadow Cover BOTTOM */ linear-gradient(to top, white 1rem, rgba(255, 255, 255, 0)) bottom
      center,
    /* Shadow BOTTOM */ linear-gradient(to top, rgba(27, 19, 53, 0.07), rgba(27, 19, 53, 0)) bottom
      center,
    white;
  background-repeat: no-repeat;
  background-size:
    3rem 100%,
    1rem 100%,
    100% 3rem,
    100% 1rem,
    100% 100%;
  background-attachment: local, scroll, local, scroll, local;
  border-radius: 0.5rem;
  border: 1px solid #e0e0e0;
  box-shadow:
    0px 1px 2px 0px rgba(18, 11, 40, 0.05),
    1px 4px 14px 0px rgba(44, 32, 80, 0.06);
}

.hazards-table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
}

.hazards-table th,
.hazards-table td {
  padding: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  //white-space: nowrap;
}

.hazards-table th {
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
}

.sticky-column {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
}

.sticky-column::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  background-color: #e0e0e0;
  z-index: 2;
}

.sticky-column:first-child {
  left: 0;
}

/* Ensure sticky header cells are above sticky columns */
.hazards-table th.sticky-column {
  z-index: 3;
}

.centered-input {
  text-align: center;
}

.center-column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
